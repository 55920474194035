//Core
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash.get';
import { Container } from '@material-ui/core';
//Local
import { Layout } from '../../../../components';
import {
  PreviewSec,
  ItemsSec,
  BannerSec,
  DepartmentsSec,
} from '../../../../components/mediaCenterSections/blogCategory';
import * as MediaCenterActions from '../../../../redux/mediaCenter/actions';

const MediaCenterHr = ({ location }: { location: Object }) => {
  const tag = decodeURIComponent(get(location, 'hash', '').replace('#', ''));
  const hrPage = useSelector((state) => state.mediaCenter.hrPage);
  const hrArticles = useSelector((state) => state.mediaCenter.hrArticles);
  const dispatch = useDispatch();

  // Did mount
  useEffect(() => {
    dispatch(MediaCenterActions.getHrPageAsync());
    dispatch(MediaCenterActions.getHrArticlesAsync());
  }, [dispatch]);

  return (
    <Layout isMediaCenter>
      <Container maxWidth="md">
        <PreviewSec
          title={get(hrPage, 'pageTitle')}
          subtitle={get(hrPage, 'pageSubtitle')}
          card={{
            ...get(hrPage, 'primaryArticle.fields', {}),
            imgUrl: get(
              hrPage,
              'primaryArticle.fields.previewImage.fields.file.url'
            ),
            url: `/media_center/${get(
              hrPage,
              'primaryArticle.fields.blogSection.fields.urlKey'
            )}/article/${get(hrPage, 'primaryArticle.sys.id')}`,
            tags: get(hrPage, 'primaryArticle.fields.tags', []).map(
              (item) => item.fields.title
            ),
          }}
        />
        <ItemsSec
          title={get(hrPage, 'secondSectionTitle')}
          tags={get(hrPage, 'tags', [])}
          items={hrArticles}
          selectedTag={tag}
        />
        <DepartmentsSec
          title={get(hrPage, 'thirdSectionTitle')}
          items={get(hrPage, 'thirdSectionDepartments', [])}
        />
      </Container>
      <BannerSec
        title={get(hrPage, 'fourthSectionTitle')}
        background={get(hrPage, 'fourthSectionBackground.fields.file.url')}
        buttonTitle={get(hrPage, 'fourthSectionButtonTitle')}
        buttonUrl={get(hrPage, 'fourthSectionButtonUrl')}
      />
    </Layout>
  );
};

export default MediaCenterHr;
